import { Injectable } from "@angular/core";
import { CRUDService, BaseService } from "projects/common/src/public-api";
import { SalaryComponent } from 'models';
import { APIConstant } from "../constant";

@Injectable({
    providedIn: "root",
})
export class SalaryComponentService extends CRUDService<SalaryComponent> {
    constructor(protected baseService: BaseService) {
        super(baseService, APIConstant.salaryComponents);
    }

}
