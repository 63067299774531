import { Injectable } from "@angular/core";
import { CRUDService, BaseService } from "projects/common/src/public-api";
import { SalaryPaymentReport } from 'models';
import { APIConstant } from "../constant";
import { Observable } from 'rxjs';

@Injectable({
    providedIn: "root",
})
export class SalaryPaymentReportService extends CRUDService<SalaryPaymentReport> {
    constructor(protected baseService: BaseService) {
        super(baseService, APIConstant.salaryPaymentHistory);
    }

    salaryPayementHistory(salaryData: any): Observable<any> {
        return this.baseService.get(`${APIConstant.salaryPaymentHistory}?startDate=${salaryData.startDate}&endDate=${salaryData.endDate}&page=${salaryData.page}&size=${salaryData.size}`);
    }
}
