import { Component, OnInit } from "@angular/core";
import { AppService, RoleService, UserAuthService } from 'projects/common/src/public-api';
import { Router } from "@angular/router";

@Component({
    template: "",
})
export class ValidateComponent implements OnInit {

    constructor(private authService: UserAuthService,
        private router: Router,
        private appService: AppService,
        private roleService: RoleService) { }

    ngOnInit() {
        // window.sessionStorage.removeItem('appName')
        this.getToken();
        setTimeout(() => {
            this.getSecurityGroups();
        }, 600);
    }

    private async getToken() {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get("token");
        if (token) {
            const code = urlParams.get("code");
            await this.authService.saveToken(token);
            window.sessionStorage.setItem('token', token);
            this.authService.saveOrgCode(code);
            window.sessionStorage.setItem('employeeId', urlParams.get('employeeId'));
            window.sessionStorage.setItem('employeeName', urlParams.get('employeeName'));
            window.sessionStorage.setItem('school', urlParams.get('schoolName'));
            window.sessionStorage.setItem('userName', urlParams.get('userName'));
            window.sessionStorage.setItem('SCHOOL_CODE', urlParams.get('code'));
            window.sessionStorage.setItem('appName', urlParams.get('appName'));
            // this.getColumnConfig();

            setTimeout(() => {
                this.router.navigateByUrl('/', { preserveQueryParams: false });
            }, 500);
        }
    }

    private getSecurityGroups() {
        this.roleService.getSecurityGroups('Dashboard');
    }
}
