import { Injectable } from '@angular/core';
import { APIConstant } from "../constant";
import { CRUDService, BaseService, CommonUtility } from 'projects/common/src/lib';
import { Observable } from 'rxjs';
import { FeeItemSetup, FeeSetup } from 'models';

@Injectable({
  providedIn: 'root'
})
export class FeeSetupService extends CRUDService<FeeSetup> {

  constructor(protected baseService: BaseService) {
    super(baseService, APIConstant.feeSetup + "/");
  }

  copy(reqBody: any): Observable<any> {
    return this.baseService.post(`${APIConstant.copy}`, reqBody);
  }

  addFeeSetup(reqBody: FeeItemSetup[]): Observable<FeeItemSetup> {
    return this.baseService.post(`${APIConstant.feeSetup}`, reqBody);
  }

  updateFeeSetup(reqBody: FeeItemSetup): Observable<FeeItemSetup> {
    const param = {
      updateStudents: false
    }
    const params = CommonUtility.convertObjectToParams(param);
    return this.baseService.put(`${APIConstant.feeSetup}?${params}`, reqBody);
  }

  deleteFeeSetup(feeSetupId: number): Observable<void> {
    const params = CommonUtility.convertObjectToParams({ feeSetupId });
    return this.baseService.delete(`${APIConstant.feeSetup}?${params}`);
  }
}
