import { Injectable } from "@angular/core";
import { CRUDService, BaseService } from "projects/common/src/public-api";
import { OtherExpenseIncomeData } from 'models';
import { APIConstant } from "../constant";
import { CommonUtility } from "projects/common/src/lib";

@Injectable({
    providedIn: "root",
})
export class OtherExpenseIncomeService extends CRUDService<OtherExpenseIncomeData> {
    constructor(protected baseService: BaseService) {
        super(baseService, APIConstant.otherExpenseIncome);
    }

    getOtherExpenseIncome(params : any, data: any){
        const query = CommonUtility.convertObjectToParams(data);
        return this.baseService.post(`${APIConstant.otherExpenseIncome}/get?${query}`, params);
    }
}
