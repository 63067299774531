import { Component, OnInit } from '@angular/core';
import { RoleService, UserAuthService } from 'projects/common/src/public-api';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(
    private authService: UserAuthService,
    private roleService: RoleService) {
  }

  ngOnInit() {

    this.getToken();
  }

  private getToken() {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    if (token) {
      this.authService.saveToken(token);
      setTimeout(() => {
        this.getSecurityGroups();
      }, 300);
    } else if (this.authService.getToken()) {
      this.getSecurityGroups();
    }
  }

  private getSecurityGroups() {
    this.roleService.getSecurityGroups('Dashboard');
  }
}
