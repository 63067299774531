import { Injectable } from "@angular/core";
import { CRUDService, BaseService } from "projects/common/src/public-api";
import { PaymentHisotry } from 'models';
import { APIConstant } from "../constant";

@Injectable({
    providedIn: "root",
})
export class PaymentHisotryService extends CRUDService<PaymentHisotry> {
    constructor(protected baseService: BaseService) {
        super(baseService, APIConstant.salaryPaymentHistory);
    }

}
