import { Injectable } from "@angular/core";
import { CRUDService, BaseService, CommonUtility } from "projects/common/src/public-api";
import { Finance } from 'models';
import { APIConstant } from "../constant";

@Injectable({
    providedIn: "root",
})
export class BankStatementService extends CRUDService<Finance> {
    constructor(protected baseService: BaseService) {
        super(baseService, APIConstant.bankStatements);
    }

    bankStatements(bankData: any): any {
        const query = CommonUtility.convertObjectToParams(bankData);
        return this.baseService.get(`${APIConstant.bankStatements}?${query}`);
    }

    getBalance(startDate: any, bankAccount: any) {
        return this.baseService.get(`${APIConstant.balance}?toDate=` + startDate + `&bankAccountId=` + bankAccount);
    }
}
