import { Injectable } from "@angular/core";
import { CRUDService, BaseService, CommonUtility } from "projects/common/src/public-api";
import { FeeReceiptTemplate } from 'models';
import { APIConstant } from "../constant";
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class FeeReceiptTemplateService extends CRUDService<FeeReceiptTemplate> {
    constructor(protected baseService: BaseService) {
        super(baseService, APIConstant.feeReceiptTemplate);
    }
    getFeeTemplates(payload: {
        isDefault: boolean;
        includePreviews: boolean;
    }): Observable<any> {
        const params = CommonUtility.convertObjectToParams(payload);
        return this.baseService.get(`${APIConstant.feeReceiptTemplate}?${params}`);
    }
}
